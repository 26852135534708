<template>
    <div class="display-block">
        <h3>Expense</h3>
        <v-card>
            <v-card-text>
                <row-item label="Date" :value="expense.txDate|dateFormat"></row-item>
                <row-item label="Mode" :value="expense.mode"></row-item>
                <row-item label="Type" :value="expense.txType"></row-item>
                <row-item label="Narration" :value="expense.narration"></row-item>
                <row-item label="Amount" :value="expense.txAmount"></row-item>
            </v-card-text>
                <v-flex text-right><v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn></v-flex>
        </v-card>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import RowItem from '@/components/RowItem'
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            id:0,
            items:[],
            expense: {
                txAmount: 0,
                mode: 'CASH',
                defaultAmountOption: 0,
                narration: "",
                txType: 'DR',
                txDate: new Date()
            },
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
                this.expense = await this.getItem(appConstants.EXPENSE_MANAGER_API + "/" + this.id)
        },
    }
}
</script>
